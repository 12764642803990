import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { CelumDirectivesModule } from '@celum/ng2base';

@Component({
  selector: 'celum-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  imports: [CommonModule, MatChipsModule, MatTooltipModule, TranslateModule, CelumDirectivesModule, CelumIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CelumChipComponent implements OnInit {
  @Input() public prefixIcon: string;
  @Input() public prefix: string;
  @Input() public text: string;
  @Input() public color: 'light' | 'dark' = 'dark';
  @Input() public disabled = false;
  @Input() public errorTooltip: string;
  @Input() public closeIcon = 'cancel-m';
  @Output() public readonly remove = new EventEmitter<boolean>();

  protected closeIconConfig: IconConfiguration;
  protected prefixIconConfig: IconConfiguration;

  public ngOnInit(): void {
    this.closeIconConfig = new IconConfiguration(this.closeIcon).withIconSize(16);
    this.prefixIconConfig = this.prefixIcon && new IconConfiguration(this.prefixIcon).withIconSize(16).withColor('inherit');
  }
}
