import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, EmptyPage, EmptyPageConfig } from '@celum/common-components';
import { CelumListModule } from '@celum/internal-components';
import { LibraryVariant } from '@celum/libraries/domain';

import { VariantsListRowComponent } from '../variants-step-list-row/variants-list-row.component';

@Component({
  imports: [CommonModule, TranslateModule, CelumListModule, CelumEmptyPageModule, VariantsListRowComponent],
  selector: 'libs-variants-list',
  templateUrl: './variants-list.component.html',
  styleUrls: ['./variants-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantsListComponent {
  @Input() public variants: LibraryVariant[];

  @Output() public readonly removeItem = new EventEmitter<LibraryVariant>();

  protected readonly emptyPageConfig: EmptyPageConfig = EmptyPage.noActionConfig(
    'no-variants-applied',
    'no-variants-applied',
    'LIBRARIES.CREATE.VARIANTS.NO_VARIANTS_SELECTED',
    'normal',
    232
  );
}
