import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule, CelumPreviewItemModule, FileCategory, IconConfiguration } from '@celum/common-components';
import { Asset } from '@celum/libraries/domain';
import { CelumDirectivesModule } from '@celum/ng2base';

@Component({
  imports: [CommonModule, TranslateModule, MatTooltipModule, CelumDirectivesModule, CelumIconModule, CelumPreviewItemModule],
  selector: 'libs-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrl: './asset-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryAssetCardComponent {
  @Input() public asset: Asset;
  public fileCategory: FileCategory = FileCategory.IMAGE;
  protected readonly unavailableIcon = new IconConfiguration('not-empty').withColor('white').withIconSize(14);
}
