import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { AssetMetadataField } from '@celum/libraries/domain';
import { TranslationModule } from '@celum/ng2base';

@Component({
  imports: [CommonModule, TranslateModule, MatTooltipModule, TranslationModule, CelumIconModule, CelumButtonModule],
  selector: 'libs-metadata-list-row',
  templateUrl: './metadata-list-row.component.html',
  styleUrls: ['./metadata-list-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetadataListRowComponent {
  @Input() public metadata: AssetMetadataField;

  @Output() public readonly remove = new EventEmitter<AssetMetadataField>();

  protected readonly icons = {
    metadata: IconConfiguration.medium('file-with-text').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20),
    remove: IconConfiguration.large('cancel-m').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20),
    warning: IconConfiguration.large('warning').withColor(ColorConstants.WARNING).withIconSize(16)
  };
}
