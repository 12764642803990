import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccess, UserDetails, UserService } from '@celum/authentication';
import { CelumMessageModule } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { LibrariesProperties } from '@celum/libraries/domain';
import { LibraryStorageService, LibraryStorageServiceState, StorageLimitStatus } from '@celum/libraries/shared';
import { AccountUser } from '@celum/shared/domain';

import { ContactDialogComponent, ContactDialogConfiguration } from './contact-dialog/contact-dialog.component';

interface StorageLimitMessageBoxComponentViewModel {
  storageStatus: StorageLimitStatus;
  isOwner: boolean;
  tenantId: string;
  owner: Partial<AccountUser>;
}

@Component({
  selector: 'libs-storage-limit-message-box',
  templateUrl: './storage-limit-message-box.component.html',
  styleUrls: ['./storage-limit-message-box.component.scss'],
  imports: [CommonModule, TranslateModule, CelumMessageModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageLimitMessageBoxComponent {
  @Input()
  public dialogConfiguration: ContactDialogConfiguration;

  protected vm$ = combineLatest(
    [this.libraryStorageService.vm$, this.userService.userInfo$.pipe(isTruthy())],
    (storageStatus, currentUser: { user: UserDetails; tenant: AccountAccess }) => this.createViewModel(storageStatus, currentUser)
  );

  protected readonly StorageLimitStatus = StorageLimitStatus;

  protected upgradeStorageUrl$ = this.vm$.pipe(
    isTruthy(),
    map(vm => `${LibrariesProperties.properties.saccWebUrl}/myaccount/${vm.tenantId}`)
  );

  constructor(
    private libraryStorageService: LibraryStorageService,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  protected upgradePlan(owner: Partial<AccountUser>): void {
    const contactDialogConfiguration: ContactDialogConfiguration = {
      user: owner,
      confirmButtonText: 'LIBRARIES.DIALOGS.CONTACT.CONFIRM',
      headerText: 'LIBRARIES.DIALOGS.CONTACT.HEADER',
      text: 'LIBRARIES.DIALOGS.CONTACT.TEXT',
      ...this.dialogConfiguration
    };

    this.dialog.open(ContactDialogComponent, { data: contactDialogConfiguration }).afterClosed().pipe(isTruthy()).subscribe();
  }

  private createViewModel(
    libraryStorageServiceState: LibraryStorageServiceState,
    userInfo: { user: UserDetails; tenant: AccountAccess }
  ): StorageLimitMessageBoxComponentViewModel {
    return {
      isOwner: userInfo.user.oid === userInfo.tenant?.ownerOid,
      storageStatus: libraryStorageServiceState.storageLimitStatus,
      tenantId: userInfo.tenant?.accountId,
      owner: {
        id: userInfo.tenant?.ownerOid,
        firstName: userInfo.tenant?.ownerName.split(' ')[0],
        lastName: userInfo.tenant?.ownerName.split(' ')[1],
        email: userInfo.tenant?.ownerEmail,
        profilePictureDownloadLink: userInfo.tenant?.profilePictureDownloadLink
      }
    };
  }
}
