import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, switchMap, tap } from 'rxjs';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { tapAllResponses } from '@celum/core';
import { AssetMetadataField, LibraryError } from '@celum/libraries/domain';
import { CelumDirectivesModule, TranslationModule } from '@celum/ng2base';
import { ErrorService } from '@celum/shared/util';

import { MetadataFilterComponent } from './metadata-filter/metadata-filter.component';
import { MetadataListComponent } from './metadata-list/metadata-list.component';
import { MetadataStepService } from './metadata-step.service';
import { LibraryDesignerServiceViewModel, LibraryWizardService } from '../../library-wizard.service';

export interface MetadataStepState {
  availableMetadataIds: string[];
  loading: boolean;
}

export interface MetadataStepViewModel {
  error: LibraryError;
  loading: boolean;
}

@Component({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatMenuModule,
    TranslateModule,
    CelumIconModule,
    MetadataListComponent,
    TranslationModule,
    CelumDirectivesModule,
    MetadataFilterComponent,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  selector: 'libs-metadata-step',
  templateUrl: './metadata-step.component.html',
  styleUrls: ['./metadata-step.component.scss', '../../styles/common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetadataStepService],
  encapsulation: ViewEncapsulation.None
})
export class MetadataStepComponent extends ComponentStore<MetadataStepState> implements OnInit {
  public vm$ = combineLatest([this.state$, this.libraryDesignerService.vm$]).pipe(
    map(([state, libraryDesignerServiceViewModel]) => this.createViewModel(state, libraryDesignerServiceViewModel))
  );

  @HostBinding('class.libs-metadata-step') public hostCls = true;

  @Input() public form: FormGroup<{
    metadata: FormControl<AssetMetadataField[] | null>;
  }>;

  @Input() public repositoryId: string;

  protected loadAvailableMetadata = this.effect<string>(repositoryId$ =>
    repositoryId$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(repositoryId => this.metadataStepService.loadAvailableMetadata(repositoryId)),
      tapAllResponses(
        (metadata: AssetMetadataField[]) => {
          this.patchState({ loading: false });

          metadata && this.patchState({ availableMetadataIds: metadata.map(availableMetadata => availableMetadata.id) });
        },
        (error: any) => {
          this.errorService.error(
            `MetadataStepComponent_${new Date().toISOString()}`,
            `LIBRARIES.CREATE.METADATA.LOAD_METADATA_ERROR.${error?.error?.errorKey}`,
            error,
            true,
            true
          );
          this.patchState({ loading: false, availableMetadataIds: [] });
        }
      )
    )
  );

  protected readonly infoIcon = IconConfiguration.small('info-icon').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(12);

  constructor(
    private libraryDesignerService: LibraryWizardService,
    private errorService: ErrorService,
    private metadataStepService: MetadataStepService
  ) {
    super({
      availableMetadataIds: [],
      loading: false
    });
  }

  public ngOnInit(): void {
    this.loadAvailableMetadata(this.repositoryId);
  }

  public removeItem(item: AssetMetadataField): void {
    const filteredMetadata = (this.form.controls.metadata.value as AssetMetadataField[]).filter(metadata => metadata.id !== item.id);
    this.form.patchValue({ metadata: filteredMetadata });
  }

  private createViewModel(state: MetadataStepState, libraryDesignerServiceViewModel: LibraryDesignerServiceViewModel): MetadataStepViewModel {
    return {
      error: libraryDesignerServiceViewModel.library?.error,
      loading: state.loading
    };
  }
}
